<template>
  <NuxtLink
    :to="typeof to === 'string' ? { name: to, params: { id: data.id } } : to"
    custom
    v-slot="{ href }"
  >
    <SearchResultCard class="mentor" @click="open(href)">
      <a class="mentor__anchor" :href="href" target="_blank"></a>
      <div class="image-wrapper">
        <img v-if="data.avatar_url" :src="data.avatar_url" alt="Аватар" class="avatar">
        <AvatarPlaceholder v-else :data="data" class="avatar" />
      </div>
      <div class="info-wrapper">
        <div class="main-info-wrapper">
          <img v-if="data.avatar_url" :src="data.avatar_url" alt="Аватар" class="avatar">
          <AvatarPlaceholder v-else small :data="data" class="avatar" />
          <ProfileMain compact :user="data" :is-new="isNew">
            <DesktopOnly><slot /></DesktopOnly>
          </ProfileMain>
        </div>
        <div class="mobile-info-wrapper">
          <MobileOnly><slot /></MobileOnly>
          <div v-if="about" class="profile-main__desc-container">
            <IconAlignLeft class="desc-icon" />
            <div class="profile-main__desc">{{ about }}</div>
          </div>
          <div class="profile-main__footer">
            <ProfileAdvantages :user="data" />
          </div>
        </div>
        <Expandable
          class="desc-wrapper"
          :max-height="120"
          expand-text="Развернуть полное описание"
          shrink-text="Свернуть полное описание"
        >
          <div class="desc">{{ data.general_about ?? 'Описание отсутствует' }}</div>
        </Expandable>
        <SkillChips class="skills" :skills="skills" :display-count="4" />
        <div class="footer-wrapper">
          <div v-if="price" class="price-wrapper">
            <div class="price-title">{{ priceTitle }}:</div>
            <CoinIndicator plain :coins="price" />
          </div>
          <slot name="action" />
        </div>
      </div>
    </SearchResultCard>
  </NuxtLink>
</template>

<script setup>
import { IconAlignLeft } from "@tabler/icons-vue"

const props = defineProps({
  data: Object,
  skills: Array,
  price: [Number, String],
  priceTitle: String,
  isNew: Boolean,
  to: {
    type: [String, Object],
    default: 'user-profile'
  }
})

provide('profile.editable', ref(false))

function open(href) {
  window.open(href, '_blank')
}

const about = computed(() => props.data.personal?.about ?? props.data.about)
</script>

<style scoped lang="scss">
.mentor {
  display: flex;
  gap: 24px;
  padding: 24px;

  &__anchor {
    display: none;
  }

  & .image-wrapper {
    width: 174px;
    height: 174px;
    flex-shrink: 0;
    &:hover {
      opacity: 1;
    }
    & .avatar {
      width: 100%;
      aspect-ratio: 1;
      border-radius: 12px;
      object-fit: cover;
    }
    @media (max-width: 920px) {
      display: none;
    }
  }
  & .info-wrapper {
    flex: 1;
    min-width: 0;
    .main-info-wrapper {
      .avatar {
        display: none;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        object-fit: cover;
        @media (max-width: 920px) {
          display: flex;
        }
      }
      @media (max-width: 920px) {
        display: flex;
        align-items: center;
        gap: 12px;
        padding-bottom: 24px;
        margin-bottom: 24px;
        border-bottom: 1px solid var(--color-separator);
      }
    }
    .mobile-info-wrapper {
      display: none;
      .desc-icon {
        display: block;
      }
      .profile-main__footer {
        margin-top: 16px;
      }
      @media (max-width: 920px) {
        display: block;
      }
    }
    & .skills {
      margin-top: 16px;
      :deep(.popper) {
        cursor: default;
      }
      @media (max-width: 920px) {
        padding: 0 15px;
        margin-left: -15px;
        width: calc(100% + 30px);
      }
    }
    & .desc-wrapper {
      margin-top: 16px;
    }
    & .desc {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: var(--color-elements-secondary);
      white-space: pre-line;
      margin-top: 0;
      @media (max-width: 920px) {
        font-size: 14px;
        line-height: 20px;
      }
    }
    & .price {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: var(--color-elements-secondary);
      & b {
        margin-left: 12px;
        font-weight: 500;
        font-size: 20px;
        line-height: 27px;
        color: var(--color-text);
        & span {
          margin-left: 3px;
          color: var(--color-elements-tertiary);
        }
      }
    }
  }
  .footer-wrapper {
    margin-top: 16px;
    padding-top: 16px;
    border-top: 1px solid var(--color-separator);
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 920px) {
      flex-direction: column;
      gap: 16px;
      align-items: stretch;
    }
    .price-wrapper {
      @media (max-width: 920px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
    .price-title {
      font-weight: 500;
      font-size: 13px;
      line-height: 24px;
      color: var(--color-elements-secondary);
      text-transform: uppercase;
      margin-bottom: 5px;
    }
  }
}
</style>
